<template>
  <ProjectList />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ProjectList from '@/components/organisms/ProjectList.vue'

export default defineComponent({
  name: 'ClientProjects',
  components: {
    ProjectList
  }
})
</script>
